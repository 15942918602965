var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex pulse-button",
    staticStyle: {
      "width": "fit-content",
      "background-color": "#fff",
      "border-radius": "80px",
      "box-shadow": "0px 4px 10px 0px #FF000059"
    }
  }, [_c('div', {
    staticClass: "d-flex-center partial-div cursor-pointer",
    staticStyle: {
      "padding-top": "10px",
      "padding-bottom": "10px"
    },
    style: "".concat(_vm.isShow ? 'border-radius: 80px 0 0 80px' : ''),
    on: {
      "click": function click($event) {
        _vm.isShow = !_vm.isShow;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@icons/alert-danger.svg"),
      "size": "16"
    }
  })]), _c('b-alert', {
    directives: [{
      name: "width-fade",
      rawName: "v-width-fade.appear",
      modifiers: {
        "appear": true
      }
    }],
    staticClass: "d-flex-center partial-div mb-0",
    style: "".concat(_vm.isShow ? 'border-radius: 0 80px 80px 0' : ''),
    attrs: {
      "variant": "transparent"
    },
    model: {
      value: _vm.isShow,
      callback: function callback($$v) {
        _vm.isShow = $$v;
      },
      expression: "isShow"
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }