<template>
  <div
    style="width: fit-content; background-color: #fff; border-radius: 80px; box-shadow: 0px 4px 10px 0px #FF000059;"
    class="d-flex pulse-button"
  >
    <div
      class="d-flex-center partial-div cursor-pointer"
      style="padding-top: 10px; padding-bottom: 10px"
      :style="`${isShow ? 'border-radius: 80px 0 0 80px' : ''}`"
      @click="isShow = !isShow"
    >
      <img
        src="@icons/alert-danger.svg"
        size="16"
      >
    </div>
    <b-alert
      v-model="isShow"
      v-width-fade.appear
      variant="transparent"
      class="d-flex-center partial-div mb-0"
      :style="`${isShow ? 'border-radius: 0 80px 80px 0' : ''}`"
    >
      <slot />
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { widthFade, heightFade } from '@core/directives/animations'

export default {
  components: {
    BAlert,
  },
  directives: {
    'width-fade': widthFade,
    'height-fade': heightFade,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    // FIXME apply for heightFade
    // fade: {
    //   type: String,
    //   default: 'width',
    // },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const isShow = ref(props.show)
    return {
      isShow,
    }
  },
}
</script>

<style lang="scss">
.partial-div {
  background-color: #FF050026;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 80px;
}
</style>
